import React from 'react'
import "normalize.css"
import { Link, graphql, useStaticQuery } from 'gatsby'
import Layout from './components/layout'
import Head from '../pages/components/head'
import aboutStyles from './sass/about.module.scss'

const AboutPage = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    aboutDescription
                }
            }
        }
    `)
    return(
        <Layout>
            <Head title="About" />
            <div className={aboutStyles.center}>
                <h2 className={aboutStyles.headingSecondary}>About me</h2>
            </div>
            <div>
                <p className={aboutStyles.description}>{data.site.siteMetadata.aboutDescription}</p>
                <p><Link className={aboutStyles.buttonText} to="/contact">Want to work with me? Reach out &rarr;</Link></p>
            </div>
        </Layout>
    )
}

export default AboutPage

// import React from 'react'
// import { Link } from 'gatsby'
// import Layout from '../pages/components/layout'
// import Head from '../pages/components/head'

// const notFound = () => {
//     return (
//         <Layout>
//             <Head title="404"/>
//             <h1>Page not found</h1>
//             <p><Link to="/">Head home</Link></p>
//         </Layout>
//     )
// }

// export default notFound